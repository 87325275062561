@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
    font-weight: 900;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf")
      format("truetype");
    font-weight: 800;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-SemiBoldItalic.ttf")
      format("truetype");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-MediumItalic.ttf")
      format("truetype");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
  }

  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-ExtraLightItalic.ttf")
      format("truetype");
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
    font-weight: 200;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
    font-weight: 100;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("./fonts/Montserrat/Montserrat-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
  }
}

@layer components {
  .bg-radial-gradient {
    @apply w-full max-w-screen-lg aspect-square flex items-center justify-center rounded-full;
    background-image: radial-gradient(var(--tw-gradient-stops));
  }
}

pre.code-block[class*=language-] {
  @apply bg-gray-800 m-0 p-7
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
